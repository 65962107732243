import { toast } from "react-toastify";
import { AppStateHandler } from "../../AppStateHandler";
import { AppStateType, CustomAnalysisLayer } from "../../Types";
export class EditCustomAnalysisLayersStateHandler {
  // New Analysis Layer screen
  initEditCustomAnalysisLayersScreen(
    this: AppStateHandler,
    customAnalysisLayer: CustomAnalysisLayer,
    updateState: (newState: AppStateType) => void
  ) {
    this.state.EditCustomAnalysisLayersPopUpVisible = true;
    this.state.AnalysisLayer = customAnalysisLayer;
    updateState(this.state);
  }

  updateAnalysisLayer(
    this: AppStateHandler,
    updateState: (newState: AppStateType) => void
  ) {
    // Check if the layer has a name and at least one point
    if (
      this.state.AnalysisLayer.name === "" ||
      this.state.AnalysisLayer.destinations.length === 0
    ) {
      return;
    }

    // Map the points to GeoJSON features
    const points = this.state.AnalysisLayer.destinations.map((destination) => ({
      name: destination.name,
      geom: {
        type: "Point",
        coordinates: [destination.geom.lng, destination.geom.lat],
      },
    }));

    this.destinationGroupApi
      .apiDestinationGroupUpdate({
        id: this.state.AnalysisLayer.id,
        destinationGroupCreateRequest: {
          name: this.state.AnalysisLayer.name,
          type: this.state.AnalysisLayer.type,
          destinations: points,
        },
      })
      .then((res) => {
        this.state.EditCustomAnalysisLayersPopUpVisible = false;
        this.loadCustomAnalysisLayers(updateState);
        updateState(this.state);
      })
      .catch((error) => {
        console.log("Error while updating analysis layer", error);
        toast.error(
          "Fout tijdens het updaten van de scenario berekening.");
      });
  }
  closeEditCustomAnalysisLayersScreen(
    this: AppStateHandler,
    updateState: (newState: AppStateType) => void
  ) {
    this.state.EditCustomAnalysisLayersPopUpVisible = false;
    this.state.customAnalysisLayers.splice(
      this.state.customAnalysisLayers.indexOf(this.state.AnalysisLayer),
      1
    );
    this.initCustomAnalysisLayersScreen(updateState);
    updateState(this.state);
  }
}
