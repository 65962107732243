import React from "react";
import "./App.css";
import { AppStateHandler } from "./AppStateHandler";
import { AppStateType, PopUpState, ScreenState } from "./Types";
import Button from "./components/Button";
import InfoMessagePopup from "./components/InfoMessagePopup";
import ProjectBar from "./components/ProjectBar";
import AdminScreen from "./screens/admin/AdminScreen";
import UsersScreen from "./screens/admin/UsersScreen";
import LoginScreen from "./screens/auth/LoginScreen";
import CustomAnalysisLayersScreen from "./screens/conf/CustomAnalysisLayersScreen";
import DocsScreen from "./screens/docs/DocsScreen";
import NewProjectScreen from "./screens/project/NewProjectScreen";
import ProjectScreen from "./screens/project/ProjectScreen";
import EditHousingPlanAreasScreen from "./screens/scenario/EditHousingPlanAreasScreen";
import EditNetworkScreen from "./screens/scenario/EditNetworkScreen";
import NewScenarioScreen from "./screens/scenario/NewScenarioScreen";
import ScenarioResultScreen from "./screens/scenario/ScenarioResultScreen";
import ContactScreen from "./screens/contact/ContactScreen";
import EnlargedComponentPopup from "./components/EnlargedComponentPopup";
import ResultItem from "./screens/scenario/ResultItem";

import logo from "./images/stratopo-logo.png";
import { ToastContainer } from "react-toastify";

export default class App extends React.Component {
  stateHandler = new AppStateHandler();
  state: AppStateType;

  constructor(props: any) {
    super(props);
    this.state = this.stateHandler.getState();
  }

  componentDidMount() {
    this.stateHandler.initApp((newState) => {
      this.setState(newState);
    });
  }

  render() {
    return (
      <>
        {(() => {
          if (!this.state.loggedIn) {
            return (
              <LoginScreen
                state={this.state}
                stateHandler={this.stateHandler}
                updateState={(newState) => {
                  this.setState(newState);
                }}
              ></LoginScreen>
            );
          } else {
            return (
              <div id="App-Container">
                <div id="App-MenuBar">
                  <ProjectBar
                    state={this.state}
                    stateHandler={this.stateHandler}
                    updateState={(newState) => {
                      this.setState(newState);
                    }}
                  ></ProjectBar>
                </div>
                <div id="App-Screen">
                  {(() => {
                    switch (this.state.popUpState) {
                      case PopUpState.waiting:
                        return (
                          <InfoMessagePopup
                            massageTitle="Even Geduld! 🚀"
                            message={
                              "We zijn bijna klaar. Bedankt voor je geduld!"
                            }
                          ></InfoMessagePopup>
                        );
                      case PopUpState.welcome:
                        return (
                          <InfoMessagePopup
                            massageTitle="Welkom bij StraTopo's Flowbiliteit"
                            message={
                              "In deze tool kunt u analyses uitvoeren in de wisselwerking tussen infrastructuur en ruimte.\n" +
                              "Pas de infrastructuur of omgeving aan en zie meteen wat voor effect dit heeft op welke doelgroepen."
                            }
                            button={
                              <Button
                                onClick={() =>
                                  this.stateHandler.closePopUp((newState) => {
                                    this.setState(newState);
                                  })
                                }
                              >
                                Begin met je eerste project!
                              </Button>
                            }
                          ></InfoMessagePopup>
                        );
                      case PopUpState.LEGEND:
                        return (
                          <InfoMessagePopup
                            massageTitle="Legenda"
                            Table={this.state.Legend}
                            button={
                              <Button
                                onClick={() =>
                                  this.stateHandler.closePopUp((newState) => {
                                    this.setState(newState);
                                  })
                                }
                              >
                                Sluiten
                              </Button>
                            }
                          ></InfoMessagePopup>
                        );
                      case PopUpState.ENLARGED_COMPONENT:
                        return (
                          this.state.selectedComponent && (
                            <EnlargedComponentPopup
                              state={this.state}
                              component={
                                <ResultItem
                                  state={this.state}
                                  resultBlock={this.state.selectedComponent}
                                />
                              }
                              onClose={() => {
                                this.stateHandler.closePopUp((newState) => {
                                  this.setState({
                                    ...newState,
                                    selectedComponent: null,
                                  });
                                });
                              }}
                            ></EnlargedComponentPopup>
                          )
                        );
                      default:
                        return null;
                    }
                  })()}
                  {(() => {
                    switch (this.state.screenState) {
                      // Project screens
                      case ScreenState.Project:
                        return (
                          <ProjectScreen
                            state={this.state}
                            stateHandler={this.stateHandler}
                            updateState={(newState) => {
                              this.setState(newState);
                            }}
                          ></ProjectScreen>
                        );
                      case ScreenState.NewProject:
                        return (
                          <NewProjectScreen
                            state={this.state}
                            stateHandler={this.stateHandler}
                            updateState={(newState) => {
                              this.setState(newState);
                            }}
                          ></NewProjectScreen>
                        );

                      // Scenario screens
                      case ScreenState.ScenarioResult:
                        return (
                          <ScenarioResultScreen
                            state={this.state}
                            stateHandler={this.stateHandler}
                            updateState={(newState) => {
                              this.setState(newState);
                            }}
                          ></ScenarioResultScreen>
                        );
                      case ScreenState.NewScenario:
                        return (
                          <NewScenarioScreen
                            state={this.state}
                            stateHandler={this.stateHandler}
                            updateState={(newState) => {
                              this.setState(newState);
                            }}
                          ></NewScenarioScreen>
                        );
                      case ScreenState.EditNetworkScreen:
                        return (
                          <EditNetworkScreen
                            state={this.state}
                            stateHandler={this.stateHandler}
                            updateState={(newState) => {
                              this.setState(newState);
                            }}
                          ></EditNetworkScreen>
                        );
                      case ScreenState.EditHousingPlanAreasScreen:
                        return (
                          <EditHousingPlanAreasScreen
                            state={this.state}
                            stateHandler={this.stateHandler}
                            updateState={(newState) => {
                              this.setState(newState);
                            }}
                          ></EditHousingPlanAreasScreen>
                        );

                      // Docs screens
                      case ScreenState.DocsScreen:
                        return (
                          <DocsScreen
                            state={this.state}
                            stateHandler={this.stateHandler}
                            updateState={(newState) => {
                              this.setState(newState);
                            }}
                          ></DocsScreen>
                        );

                      // Contact screens
                      case ScreenState.ContactScreen:
                        return (
                          <ContactScreen
                            state={this.state}
                            stateHandler={this.stateHandler}
                            updateState={(newState) => {
                              this.setState(newState);
                            }}
                          ></ContactScreen>
                        );

                      // Custom Analysis Layers screens
                      case ScreenState.CustomAnalysisLayersScreen:
                        return (
                          <CustomAnalysisLayersScreen
                            state={this.state}
                            stateHandler={this.stateHandler}
                            updateState={(newState) => {
                              this.setState(newState);
                            }}
                          ></CustomAnalysisLayersScreen>
                        );
                      case ScreenState.AdminScreen:
                        return (
                          <AdminScreen
                            state={this.state}
                            stateHandler={this.stateHandler}
                            updateState={(newState) => {
                              this.setState(newState);
                            }}
                          ></AdminScreen>
                        );
                      case ScreenState.UsersScreen:
                        return (
                          <UsersScreen
                            state={this.state}
                            stateHandler={this.stateHandler}
                            updateState={(newState) => {
                              this.setState(newState);
                            }}
                          ></UsersScreen>
                        );

                      default:
                        return null;
                    }
                  })()}
                </div>
                <div id="PoweredByTag">
                  Powered by StraTopo
                  <img src={logo} alt="StraTopo Logo" id="PoweredByLogo" />
                </div>
              </div>
            );
          }
        })()}

        <ToastContainer
          toastClassName={(context) => `Toastify__toast custom-toast ${context?.type}`}
          progressClassName="custom-toast-progress-bar"
          position="bottom-right"
          autoClose={5000}
          newestOnTop />
      </>
    );
  }
}
