import React from "react";
import "./ResultItemSideBar.css";
import { AppStateType, PopUpState } from "src/Types";
import { AppStateHandler } from "src/AppStateHandler";
import { IoResize } from "react-icons/io5";
import { IoIosInformationCircleOutline } from "react-icons/io";
import { ContentTypeEnum, ResultBlock } from "src/client/lib";
import { FaImage } from "react-icons/fa";
import { PiFileCsvFill } from "react-icons/pi";
import { TbMapDown } from "react-icons/tb";
import { TiCompass } from "react-icons/ti";
import { toast } from "react-toastify";

interface ResultItemSideBarProps {
  state: AppStateType;
  stateHandler: AppStateHandler;
  updateState: (newState: AppStateType) => void;
  resultBlock: ResultBlock;
}

export default class ResultItemSideBar extends React.Component<ResultItemSideBarProps> {
  render() {
    const resultBlock = this.props.resultBlock;

    return (
      <div className="ScenarioResultScreen-Tab">
        <div className="tooltip">
          <button
            className="ScenarioResultScreen-Icon-button"
            onClick={() => {
              this.props.state.Legend = resultBlock.legend as any;
              this.props.state.selectedComponent = resultBlock;
              this.props.state.popUpState = PopUpState.ENLARGED_COMPONENT;
              this.props.updateState(this.props.state);
            }}
          >
            <IoResize size={15} />
          </button>
          <span className="tooltiptext">Vergroten</span>
        </div>

        <div className="tooltip">
          <button
            className="ScenarioResultScreen-Icon-button"
            onClick={() => {
              toast.info(`${resultBlock.description || resultBlock.title}`);
            }}
          >
            <IoIosInformationCircleOutline size={15} />
          </button>
          <span className="tooltiptext">Informatie</span>
        </div>

        {(() => {
          const contentTypesExport: ContentTypeEnum[] = [
            ContentTypeEnum.Heatmap,
            ContentTypeEnum.Graph,
            ContentTypeEnum.GraphDiff,
            ContentTypeEnum.HeatmapDiff,
            ContentTypeEnum.MunicipalityGraph,
            // ContentTypeEnum.MunicipalityGraphDiff,
            // ContentTypeEnum.HousingPlanGraph,
          ];

          if (
            contentTypesExport.includes(
              resultBlock.contentType as ContentTypeEnum
            )
          ) {
            return (
              <div className="tooltip">
                <button
                  className="ScenarioResultScreen-Icon-button"
                  onClick={() => {
                    this.props.stateHandler.exportBlock(
                      resultBlock.id,
                      resultBlock.title,
                      "png",
                      this.props.updateState
                    );
                  }}
                >
                  <FaImage size={15} />
                </button>
                <span className="tooltiptext">Exporteren als afbeelding</span>
              </div>
            );
          }

          return null; // Add this line to handle the case when neither condition is met
        })()}

        {(() => {
          const contentTypesExport: ContentTypeEnum[] = [
            ContentTypeEnum.Graph,
            ContentTypeEnum.GraphDiff,
            ContentTypeEnum.MunicipalityGraph,
            ContentTypeEnum.MunicipalityGraphDiff,
            ContentTypeEnum.HousingPlanGraph,
          ];

          if (
            contentTypesExport.includes(
              resultBlock.contentType as ContentTypeEnum
            )
          ) {
            return (
              <div className="tooltip">
                <button
                  className="ScenarioResultScreen-Icon-button"
                  onClick={() => {
                    this.props.stateHandler.exportBlock(
                      resultBlock.id,
                      resultBlock.title,
                      "csv",
                      this.props.updateState
                    );
                  }}
                >
                  <PiFileCsvFill size={15} />
                </button>
                <span className="tooltiptext">Exporteren als CSV</span>
              </div>
            );
          }

          return null; // Add this line to handle the case when neither condition is met
        })()}

        {(() => {
          const contentTypesExport: ContentTypeEnum[] = [
            ContentTypeEnum.Heatmap,
            ContentTypeEnum.HeatmapDiff,
          ];

          if (
            contentTypesExport.includes(
              resultBlock.contentType as ContentTypeEnum
            )
          ) {
            return (
              <div className="tooltip">
                <button
                  className="ScenarioResultScreen-Icon-button"
                  onClick={() => {
                    this.props.stateHandler.exportBlock(
                      resultBlock.id,
                      resultBlock.title,
                      "gpkg",
                      this.props.updateState
                    );
                  }}
                >
                  <TbMapDown size={15} />
                </button>
                <span className="tooltiptext">Exporteren als geopackage</span>
              </div>
            );
          }

          return null; // Add this line to handle the case when neither condition is met
        })()}
        {(() => {
          const contentTypeLegend: ContentTypeEnum[] = [
            ContentTypeEnum.Overview,
            ContentTypeEnum.Isochrone,
            ContentTypeEnum.Heatmap,
            ContentTypeEnum.HeatmapDiff,
            ContentTypeEnum.OriginDestinationNetwork,
            ContentTypeEnum.OriginDestinationNetworkDiff,
          ];

          if (
            contentTypeLegend.includes(
              resultBlock.contentType as ContentTypeEnum
            )
          ) {
            return (
              <div className="tooltip">
                <button
                  className="ScenarioResultScreen-Icon-button"
                  onClick={() => {
                    this.props.state.Legend = resultBlock.legend as any;
                    this.props.state.popUpState = PopUpState.LEGEND;
                    this.props.updateState(this.props.state);
                  }}
                >
                  <TiCompass size={15} />
                </button>
                <span className="tooltiptext">Legenda</span>
              </div>
            );
          }
          return null; // Add this line to handle the case when neither condition is met
        })()}
      </div>
    );
  }
}
