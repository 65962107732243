import { toast } from "react-toastify";
import { AppStateHandler } from "../../AppStateHandler";
import { AppStateType, PopUpState, ScreenState } from "../../Types";

export class NewScenarioStateHandler {
  // new project screen
  initNewScenarioScreen(
    this: AppStateHandler,
    updateState: (newState: AppStateType) => void
  ) {
    this.state.newScenario.name = "";
    this.state.screenState = ScreenState.NewScenario;
    updateState(this.state);
  }

  updateNewScenarioName(
    this: AppStateHandler,
    newScenarioName: string | null,
    updateState: (newState: AppStateType) => void
  ) {
    this.state.newScenario.name = newScenarioName;
    updateState(this.state);
  }

  saveNewScenario(
    this: AppStateHandler,
    editNetwork: boolean,
    updateState: (newState: AppStateType) => void
  ) {
    if (this.state.selectedProject !== null) {
      if (
        this.state.newScenario.name === null ||
        this.state.newScenario.name === ""
      ) {
        toast.warning(
          "Vul alstublieft een scenario-naam in.",
        );
        return;
      } else {
        this.state.popUpState = PopUpState.waiting;
        this.scenarioApi
          .apiScenarioCreate({
            scenarioCreateRequest: {
              name: this.state.newScenario.name as string,
              project: this.state.selectedProject,
            },
          })
          .then((createScenarioResponse) => {
            // Reset the scenario
            this.state.newScenario.name = null;

            // Select the created scenario
            this.state.selectedScenario = createScenarioResponse.id;
            updateState(this.state);

            // Reload the projects
            this.projectApi
              .apiProjectList()
              .then((response) => {
                this.state.projects = response;

                this.sortProjects(updateState);

                updateState(this.state);

                // Close the waiting popup
                this.closePopUp(updateState);

                // Navigate to next screen
                if (editNetwork) {
                  this.changeScreen(ScreenState.EditNetworkScreen, updateState);
                } else {
                  this.changeScreen(ScreenState.ScenarioResult, updateState);
                }
              })
              .catch((error) => {
                console.log("Error while loading projects", error);
              });
          })
          .catch((error) => {
            console.log("Error while creating scenario", error);
            //sign out
          });
        updateState(this.state);
      }
    }
  }
}
