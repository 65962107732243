import { LineString, Point, Position } from "geojson";
import * as L from "leaflet";
import React from "react";
import { AppStateHandler } from "src/AppStateHandler";
import { AppStateType, DrawMode, EditedVertex, Road } from "src/Types";
import { EditTypeEnum } from "src/client/lib/models";
import "./EditNetworkMap.css";
import { toast } from "react-toastify";

interface EditNetworkMapProps {
  state: AppStateType;
  stateHandler: AppStateHandler;
  updateState: (newState: AppStateType) => void;
}

export default class EditNetworkMap extends React.Component<EditNetworkMapProps> {
  map?: L.Map;
  mapHolder: React.RefObject<HTMLDivElement>;

  newRoad: {
    source: number | null;
    geom: LineString;
    tempLayerGroup: L.LayerGroup<any>;
    layerGroup: L.LayerGroup<any>;
  } = {
      source: null,
      geom: {
        type: "LineString",
        coordinates: [],
      },
      tempLayerGroup: L.layerGroup(),
      layerGroup: L.layerGroup(),
    };

  // Previous state
  previousSelectedNetwork: {
    roads: string;
    vertices: string;
    boundingBox: string;
  } = { roads: "[]", vertices: "[]", boundingBox: "null" };
  previousEditedRoads: string = "[]";
  // previousSelectedPointGroup: { points: string } = { points: "[]" };
  previousDrawMode: DrawMode = DrawMode.Off;
  previousEditedVertices: string = "[]";

  // Groups
  boundingBoxGroup: L.LayerGroup<any> = L.layerGroup();
  networkRoadsGroup: L.LayerGroup<any> = L.layerGroup();
  networkVerticesGroup: L.LayerGroup<any> = L.layerGroup();
  editedRoadsGroup: L.LayerGroup<any> = L.layerGroup();
  editedVerticesGroup: L.LayerGroup<any> = L.layerGroup();
  //   pointsGroup: L.LayerGroup<any> = L.layerGroup();
  projectAreaLayer: L.LayerGroup<any> = L.layerGroup();

  //   // New point
  //   mouseDownPosition: L.Point | null = null;

  constructor(props: any) {
    super(props);
    this.mapHolder = React.createRef();
  }

  render() {
    return <div ref={this.mapHolder} id="EditNetworkMap" />;
  }

  positionToLatLonTuple = (pos: Position): L.LatLngTuple => {
    return [pos[1], pos[0]];
  };

  componentDidMount() {
    if (
      this.mapHolder.current != null &&
      this.mapHolder.current.childNodes.length === 0
    ) {
      // Initialize map
      const mapElement = document.createElement("div");
      mapElement.style.height = "100%";
      mapElement.style.width = "100%";
      mapElement.id = "map";

      this.mapHolder.current.appendChild(mapElement);

      this.map = L.map("map", {
        zoomControl: false,
        attributionControl: true,
        zoomSnap: 0.5,
        minZoom: 1,
        maxZoom: 21,
      });
      this.map.setView(
        [
          this.props.state.editNetwork.map.center_lat,
          this.props.state.editNetwork.map.center_lng,
        ],
        this.props.state.editNetwork.map.zoom
      );

      // Add layers
      let layer = L.tileLayer(
        "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
        {
          maxNativeZoom: 15,
          maxZoom: 21,
          opacity: 1,
        }
      );
      layer.addTo(this.map);
      this.newRoad.tempLayerGroup.addTo(this.map);
      this.newRoad.layerGroup.addTo(this.map);

      this.boundingBoxGroup.addTo(this.map);
      this.map.createPane("boundingBoxPane");
      this.map.getPane("boundingBoxPane")!.style.zIndex = "645";

      this.networkRoadsGroup.addTo(this.map);
      this.map.createPane("networkRoadsPane");
      this.map.getPane("networkRoadsPane")!.style.zIndex = "646";

      this.editedRoadsGroup.addTo(this.map);
      this.map.createPane("editedRoadsPane");
      this.map.getPane("editedRoadsPane")!.style.zIndex = "647";

      this.networkVerticesGroup.addTo(this.map);
      this.map.createPane("networkVerticesPane");
      this.map.getPane("networkVerticesPane")!.style.zIndex = "648";

      this.editedVerticesGroup.addTo(this.map);
      this.map.createPane("editedVerticesPane");
      this.map.getPane("editedVerticesPane")!.style.zIndex = "649";

      //   this.pointsGroup.addTo(this.map);
      this.projectAreaLayer.addTo(this.map);

      this.map.on("click", (event) => {
        if (this.map) {
          // Add coordinate to new road
          if (this.props.state.editNetwork.drawMode === DrawMode.AddRoad) {
            if (this.newRoad.geom.coordinates.length > 0) {
              let newPoint: Position = [event.latlng.lng, event.latlng.lat];
              this.newRoad.geom.coordinates.push(newPoint);
              let layer = L.geoJSON(this.newRoad.geom, {
                style: { interactive: false, color: "#2EC754" },
              });
              this.newRoad.layerGroup.clearLayers();
              layer.addTo(this.newRoad.layerGroup);
            }
          } else if (
            this.props.state.editNetwork.drawMode === DrawMode.AddVertex
          ) {
            const newVertex: EditedVertex = {
              id: null,
              gid: null,
              geom: {
                type: "Point",
                coordinates: [event.latlng.lng, event.latlng.lat],
              },
            };

            this.props.stateHandler.editNetworkAddVertex(
              newVertex,
              this.props.updateState
            );
          }
        }
      });

      // Update while moving
      this.map.on("mousemove", (event) => {
        if (this.map) {
          // Draw dashed line between the mouse and the last coordinate of a new road
          if (this.props.state.editNetwork.drawMode === DrawMode.AddRoad) {
            if (this.newRoad.geom.coordinates.length > 0) {
              let latlng: L.LatLngTuple = [event.latlng.lat, event.latlng.lng];
              let tempLayer = L.polyline(
                [
                  this.positionToLatLonTuple(
                    this.newRoad.geom.coordinates[
                    this.newRoad.geom.coordinates.length - 1
                    ]
                  ),
                  latlng,
                ],
                {
                  interactive: false,
                  color: "#2EC754",
                  dashArray: "5, 5",
                  dashOffset: "0",
                }
              );
              this.newRoad.tempLayerGroup.clearLayers();
              tempLayer.addTo(this.newRoad.tempLayerGroup);
            }
          }
        }
      });

      // Init bounding box, zoom & center
      const center = this.map.getCenter();
      const bounds = this.map.getBounds();
      this.props.stateHandler.editNetworkMapMoved(
        this.map.getZoom(),
        center.lat,
        center.lng,
        bounds.getWest(), //x_min
        bounds.getSouth(), //y_min
        bounds.getEast(), //x_max
        bounds.getNorth(), //y_max
        this.props.updateState
      );

      // Update bouding box, zoom & center when map is moved
      this.map.on("moveend", (event) => {
        const center = event.target.getCenter();
        const bounds = event.target.getBounds();
        this.props.stateHandler.editNetworkMapMoved(
          event.target.getZoom(),
          center.lat,
          center.lng,
          bounds.getWest(), //x_min
          bounds.getSouth(), //y_min
          bounds.getEast(), //x_max
          bounds.getNorth(), //y_max
          this.props.updateState
        );
      });

      // if (this.props.state.resultBlocks.length > 0) {
      //   const projectAreaGeoJSON = JSON.parse(
      //     this.props.state.resultBlocks[0].content
      //   );

      //   if ("features" in projectAreaGeoJSON && "type" in projectAreaGeoJSON) {
      //     projectAreaGeoJSON.features.forEach((feature: any) => {
      //       if (feature.name === "overview") {
      //         const GEOMETRY = JSON.parse(feature.geometry);
      //         console.log(GEOMETRY);
      //         const projectAreaLayer = L.geoJSON(GEOMETRY, {
      //           style: {
      //             color: "#5a6a85",
      //             weight: 2,
      //             opacity: 1,
      //             fillOpacity: 0.3,
      //           },
      //         });
      //         projectAreaLayer.addTo(this.projectAreaLayer);
      //       }
      //     });
      //   }
      // }
    }
  }

  componentDidUpdate() {
    // Change draw mode
    if (this.props.state.editNetwork.drawMode !== this.previousDrawMode) {
      if (this.map) {
        if (this.props.state.editNetwork.drawMode === DrawMode.Off) {
          this.newRoad.source = null;
          this.newRoad.geom = {
            type: "LineString",
            coordinates: [],
          };
          this.newRoad.layerGroup.clearLayers();
          this.newRoad.tempLayerGroup.clearLayers();
          this.map.dragging.enable();
        } else if (this.props.state.editNetwork.drawMode === DrawMode.AddRoad) {
          this.map.dragging.disable();
        } else if (
          this.props.state.editNetwork.drawMode === DrawMode.EditRoad
        ) {
          this.map.dragging.enable();
        } else if (
          [DrawMode.Delete, DrawMode.DeleteCreatedOnly].includes(
            this.props.state.editNetwork.drawMode
          )
        ) {
          this.map.dragging.enable();
        }
        this.previousDrawMode = this.props.state.editNetwork.drawMode;
      }
    }
    // Selected network - Bounding box
    if (
      JSON.stringify(
        this.props.state.editNetwork.selectedTopology.boundingBox
      ) !== this.previousSelectedNetwork.boundingBox
    ) {
      this.boundingBoxGroup.clearLayers();
      if (this.props.state.editNetwork.selectedTopology.boundingBox) {
        var bounds: L.LatLngBoundsExpression = [
          [
            this.props.state.editNetwork.selectedTopology.boundingBox.yMin,
            this.props.state.editNetwork.selectedTopology.boundingBox.xMin,
          ],
          [
            this.props.state.editNetwork.selectedTopology.boundingBox.yMax,
            this.props.state.editNetwork.selectedTopology.boundingBox.xMax,
          ],
        ];
        const layer = L.rectangle(bounds, {
          interactive: false,
          color: "#4c6071",
          weight: 3,
          opacity: 1,
          fillOpacity: 0,
          dashArray: "5, 8",
          dashOffset: "0",
          pane: "boundingBoxPane",
        });
        layer.addTo(this.boundingBoxGroup);
      }
      this.previousSelectedNetwork.boundingBox = JSON.stringify(
        this.props.state.editNetwork.selectedTopology.boundingBox
      );
    }
    // Selected network - Roads
    if (
      JSON.stringify(this.props.state.editNetwork.selectedTopology.roads) !==
      this.previousSelectedNetwork.roads
    ) {
      this.networkRoadsGroup.clearLayers();
      this.props.state.editNetwork.selectedTopology.roads.forEach((road) => {
        const geom: LineString = {
          type: "LineString",
          coordinates: road.geom.coordinates,
        };
        const layer = L.geoJSON(geom, {
          style: { color: "#4187ee", weight: 3 },
          pane: "networkRoadsPane",
        });
        layer.on("mouseover", (_) => {
          this.props.stateHandler.updateRoadInfo(
            [
              { attribute: "Source ID", value: road.source.toString() },
              { attribute: "Target ID", value: road.target.toString() },
              {
                attribute: "Length",
                value: Math.round(road.lengthM * 10) / 10 + " m",
              },
              { attribute: "Speed", value: road.speed + " km/h" },
              {
                attribute: "Reverse speed",
                value: road.reverseSpeed + " km/h",
              },
            ],
            this.props.updateState
          );
        });
        layer.on("mouseout", (_) => {
          this.props.stateHandler.updateRoadInfo([], this.props.updateState);
        });
        layer.on("mousedown", (event) => {
          if (this.props.state.editNetwork.drawMode === DrawMode.EditRoad) {
            const roadToUpgrade: Road = {
              id: null,
              gid: road.gid,
              edit_type: EditTypeEnum.Edit,
              speed: road.speed,
              reverse_speed: road.reverseSpeed,
              geom: {
                type: "LineString",
                coordinates: road.geom.coordinates,
              },
              source: road.source,
              target: road.target,
            };
            this.props.stateHandler.editNetworkEditRoad(
              roadToUpgrade,
              event.originalEvent.shiftKey,
              this.props.updateState
            );
            // this.props.changeDrawMode(DrawMode.Off);
          } else if (
            this.props.state.editNetwork.drawMode === DrawMode.Delete
          ) {
            this.props.stateHandler.editNetworkDeleteBaseRoad(
              road,
              this.props.updateState
            );
          } else if (
            this.props.state.editNetwork.drawMode === DrawMode.DeleteCreatedOnly
          ) {
            toast.error("Wegen van het basisnetwerk kunnen niet verwijderd worden.");
          }
        });
        layer.addTo(this.networkRoadsGroup);
      });
      this.previousSelectedNetwork.roads = JSON.stringify(
        this.props.state.editNetwork.selectedTopology.roads
      );
    }
    // Edited Network
    if (
      JSON.stringify(this.props.state.editNetwork.editedRoads) !==
      this.previousEditedRoads
    ) {
      this.editedRoadsGroup.clearLayers();
      this.props.state.editNetwork.editedRoads.forEach((road) => {
        let roadEditStyling = { color: "#2EC754", weight: 6 };
        if (road.edit_type === EditTypeEnum.Add) {
          roadEditStyling = { color: "#2EC754", weight: 6 };
        } else if (road.edit_type === EditTypeEnum.Edit) {
          roadEditStyling = { color: "#D1B411", weight: 6 };
        } else if (road.edit_type === EditTypeEnum.Delete) {
          roadEditStyling = { color: "#ff5d5d", weight: 6 };
        }
        const layer = L.geoJSON(road.geom, {
          style: roadEditStyling,
          pane: "editedRoadsPane",
        });
        layer.on("mouseover", (_) => {
          let roadEditType = "-";
          if (road.edit_type === EditTypeEnum.Add) {
            roadEditType = "Toegevoegd";
          } else if (road.edit_type === EditTypeEnum.Edit) {
            roadEditType = "Opgewaardeerd";
          } else if (road.edit_type === EditTypeEnum.Delete) {
            roadEditType = "Verwijderd";
          }
          this.props.stateHandler.updateRoadInfo(
            [
              { attribute: "Source ID", value: road.source.toString() },
              { attribute: "Target ID", value: road.target.toString() },
              { attribute: "Wegtype", value: roadEditType },
              { attribute: "Speed", value: road.speed + " km/h" },
              {
                attribute: "Reverse speed",
                value: road.reverse_speed + " km/h",
              },
            ],
            this.props.updateState
          );
        });
        layer.on("mouseout", (_) => {
          this.props.stateHandler.updateRoadInfo([], this.props.updateState);
        });
        layer.on("mousedown", (_) => {
          if (
            [DrawMode.Delete, DrawMode.DeleteCreatedOnly].includes(
              this.props.state.editNetwork.drawMode
            )
          ) {
            this.props.stateHandler.editNetworkDeleteRoad(
              road.id,
              this.props.updateState
            );
          }

          if (this.props.state.editNetwork.drawMode === DrawMode.EditRoad) {
            console.log(this.props.state.editNetwork.drawMode, 452);
            this.props.stateHandler.editNetworkEditRoad(
              road,
              false,
              this.props.updateState
            );
          }
        });
        layer.addTo(this.editedRoadsGroup);
      });
      this.previousEditedRoads = JSON.stringify(
        this.props.state.editNetwork.editedRoads
      );
    }
    // Selected network - Vertices
    if (
      JSON.stringify(this.props.state.editNetwork.selectedTopology.vertices) !==
      this.previousSelectedNetwork.vertices
    ) {
      this.networkVerticesGroup.clearLayers();
      this.props.state.editNetwork.selectedTopology.vertices.forEach(
        (vertex) => {
          const geom: Point = {
            type: "Point",
            coordinates: vertex.geom.coordinates,
          };
          const layer = L.geoJSON(geom, {
            pointToLayer: (_, latlng) => {
              return L.circleMarker(latlng, {
                radius: 3,
                fillColor: "#FFFFFF",
                color: "#4187ee",
                weight: 2,
                opacity: 1,
                fillOpacity: 1,
                pane: "networkVerticesPane",
              });
            },
          });
          layer.on("mouseover", (_) => {
            this.props.stateHandler.updateRoadInfo(
              [{ attribute: "ID", value: vertex.id.toString() }],
              this.props.updateState
            );
          });
          layer.on("mouseout", (_) => {
            this.props.stateHandler.updateRoadInfo([], this.props.updateState);
          });
          layer.on("mousedown", (_) => {
            if (this.props.state.editNetwork.drawMode === DrawMode.AddRoad) {
              if (this.newRoad.geom.coordinates.length === 0) {
                this.newRoad.source = vertex.id;
                this.newRoad.geom.coordinates = [vertex.geom.coordinates];
              } else {
                if (this.newRoad.source !== vertex.id) {
                  this.newRoad.geom.coordinates.push(vertex.geom.coordinates);
                  if (this.newRoad.source) {
                    const newRoad: Road = JSON.parse(
                      JSON.stringify({
                        id: null,
                        gid: null,
                        edit_type: EditTypeEnum.Add,
                        // TODO: check if this is correct
                        speed: 18,
                        reverse_speed: 18,
                        geom: this.newRoad.geom,
                        source: this.newRoad.source,
                        target: vertex.id,
                      })
                    );
                    this.props.stateHandler.editNetworkAddRoad(
                      newRoad,
                      this.props.updateState
                    );
                    this.newRoad.source = null;
                    this.newRoad.geom.coordinates = [];
                    this.newRoad.layerGroup.clearLayers();
                    this.newRoad.tempLayerGroup.clearLayers();
                  }
                }
              }
            }
          });
          layer.addTo(this.networkVerticesGroup);
        }
      );
      this.previousSelectedNetwork.vertices = JSON.stringify(
        this.props.state.editNetwork.selectedTopology.vertices
      );
    }
    // Edited vertices
    if (
      JSON.stringify(this.props.state.editNetwork.editedVertices) !==
      this.previousEditedVertices
    ) {
      this.editedVerticesGroup.clearLayers();
      this.props.state.editNetwork.editedVertices.forEach((vertex) => {
        let vertexColor = "#2EC754";
        if (vertex.gid === null) {
          vertexColor = "#AB2739";
        }

        const layer = L.geoJSON(vertex.geom, {
          pointToLayer: (_, latlng) => {
            return L.circleMarker(latlng, {
              radius: 3,
              fillColor: "#FFFFFF",
              color: vertexColor,
              weight: 2,
              opacity: 1,
              fillOpacity: 1,
              pane: "editedVerticesPane",
            });
          },
        });
        layer.on("mouseover", (_) => {
          if (vertex.gid !== null) {
            this.props.stateHandler.updateRoadInfo(
              [{ attribute: "ID", value: vertex.gid.toString() }],
              this.props.updateState
            );
          } else {
            this.props.stateHandler.updateRoadInfo(
              [{ attribute: "ID", value: "Vertex not saved" }],
              this.props.updateState
            );
          }
        });
        layer.on("mouseout", (_) => {
          this.props.stateHandler.updateRoadInfo([], this.props.updateState);
        });
        layer.on("mousedown", (_) => {
          if (
            vertex.gid !== null &&
            this.props.state.editNetwork.drawMode === DrawMode.AddRoad
          ) {
            if (this.newRoad.geom.coordinates.length === 0) {
              this.newRoad.source = vertex.gid;
              this.newRoad.geom.coordinates = [vertex.geom.coordinates];
            } else {
              if (this.newRoad.source !== vertex.gid) {
                this.newRoad.geom.coordinates.push(vertex.geom.coordinates);
                if (this.newRoad.source) {
                  const newRoad: Road = JSON.parse(
                    JSON.stringify({
                      id: null,
                      gid: null,
                      edit_type: EditTypeEnum.Add,
                      // TODO: check if this is correct
                      speed: 18,
                      reverse_speed: 18,
                      geom: this.newRoad.geom,
                      source: this.newRoad.source,
                      target: vertex.gid,
                    })
                  );
                  this.props.stateHandler.editNetworkAddRoad(
                    newRoad,
                    this.props.updateState
                  );
                  this.newRoad.source = null;
                  this.newRoad.geom.coordinates = [];
                  this.newRoad.layerGroup.clearLayers();
                  this.newRoad.tempLayerGroup.clearLayers();
                }
              }
            }
          } else if (
            [DrawMode.Delete, DrawMode.DeleteCreatedOnly].includes(
              this.props.state.editNetwork.drawMode
            )
          ) {
            this.props.stateHandler.editNetworkDeleteEditedVertex(
              vertex.id,
              this.props.updateState
            );
          }
        });
        layer.addTo(this.editedVerticesGroup);
      });
      this.previousEditedVertices = JSON.stringify(
        this.props.state.editNetwork.editedVertices
      );
    }
  }
}
