import { toast } from "react-toastify";
import { AppStateHandler } from "../../AppStateHandler";
import { AppStateType, ScreenState } from "../../Types";
import { ScenarioTypeEnum } from "../../client/lib/models";
export class ProjectStateHandler {
  // project screen
  initProjectScreen(
    this: AppStateHandler,
    updateState: (newState: AppStateType) => void
  ) {
    this.state.screenState = ScreenState.Project;
    this.getProject(updateState);
    updateState(this.state);
  }

  getProject(
    this: AppStateHandler,
    updateState: (newState: AppStateType) => void
  ) {
    const scenario = this.state.projects.find(
      (project) => project.id === this.state.selectedProject
    );
    if (scenario) {
      this.state.projectinfo.scenarioType = scenario.scenarioType;
    }
    this.projectApi
      .apiProjectRetrieve({ id: this.state.selectedProject || 0 })
      .then((project) => {
        this.state.projectinfo.name = project.name;
        this.state.projectinfo.scenarioType = project.scenarioType;
        this.state.projectinfo.baseTopology = project.baseTopology;
        this.state.projectinfo.baseTopologyName = project.baseTopologyName;
        this.state.projectinfo.destinationGroup = project.destinationGroup;
        this.fetchDestinationGroupList(updateState);
        updateState(this.state);
      });
  }

  projectInfoUpdateForm(
    this: AppStateHandler,
    newFormValues: {
      projectName?: string;
      destinationGroup_id?: number;
    },
    callback: (newState: AppStateType) => void
  ) {
    if (newFormValues.projectName !== undefined) {
      this.state.projectinfo.name = newFormValues.projectName;
    }
    if (newFormValues.destinationGroup_id !== undefined) {
      if (
        this.state.projectinfo.destinationGroup.includes(
          newFormValues.destinationGroup_id
        )
      ) {
        this.state.projectinfo.destinationGroup =
          this.state.projectinfo.destinationGroup.filter(
            (id) => id !== newFormValues.destinationGroup_id
          );
      } else {
        this.state.projectinfo.destinationGroup.push(
          newFormValues.destinationGroup_id
        );
      }
    }
    callback(this.state);
  }

  updateProject(
    this: AppStateHandler,
    updateState: (newState: AppStateType) => void
  ) {
    if (
      this.state.projectinfo.scenarioType ===
      ScenarioTypeEnum.BicycleOrientedDevelopment &&
      this.state.projectinfo.destinationGroup.length === 0
    ) {
      toast.warning(
        "Kies alstublieft minstens 1 bestemming.")
      return;
    }
    this.projectApi
      .apiProjectUpdate({
        id: this.state.selectedProject as number,
        projectResponse: this.state.projectinfo,
      })
      .then((response) => {
        this.initProjectScreen(updateState);
        this.loadProjects(updateState, false);
      });
  }

  deleteProject(
    this: AppStateHandler,
    updateState: (newState: AppStateType) => void
  ) {
    this.projectApi
      .apiProjectDestroy({ id: this.state.selectedProject as number })
      .then((response) => {
        this.loadProjects(() => {
          if (this.state.projects.length > 0) {
            this.changeScreen(ScreenState.ScenarioResult, updateState);
          } else {
            this.changeScreen(ScreenState.NewProject, updateState);
          }
        });
      });
  }
}
